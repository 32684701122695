<template>
    <v-main>
        <template v-slot:sidebar>
            <v-section>
                <v-section-heading>
                    Navigation
                </v-section-heading>

                <v-section-links :menu="menu" />
            </v-section>
        </template>

        <template v-slot:default>
            <v-model-form :id="$route.params.payout" module="payoutModule">
                <template v-slot:default="{ model, refreshModel }">
                    <v-section>
                        <v-section-heading>
                            <template v-slot:default>
                                Payout #{{ model.payout_id }}
                            </template>

                            <template v-slot:description>
                                View the payout for {{ model.user.name }}.
                            </template>

                            <template v-if="!(model.is_paid || model.is_refunded) && $me.hasPermission('payouts.update')" v-slot:actions>
                                <v-button :disabled="isLoading" @click="markAsPaid(model.payout_id)">
                                    Mark as Paid
                                </v-button>
                            </template>
                        </v-section-heading>

                        <v-ghost :payout="model" @payout-updated="refreshModel" />
                    </v-section>
                </template>
            </v-model-form>
        </template>
    </v-main>
</template>

<script>
import PayoutService from "@/services/modules/payout-service";

export default {
    data() {
        return {
            /**
             * @todo Write a description.
             */
            filters: {},

            /**
             * @todo Write a description.
             */
            menu: [
                {
                    label: "Payout Details",
                    route: { name: "payouts.show.summary" },
                    description: "View information about the payout including the payout lines.",
                    icon: "fa fa-credit-card",
                },
            ],
        };
    },
    methods: {
        markAsPaid(payoutId) {
            this.toggleLoading();

            PayoutService.update(payoutId, { is_paid: true })
                .then(() => {
                    this.$router.push({
                        name: "payouts.index",
                    });
                })
                .finally(() => {
                    this.toggleLoading();
                });
        },
    },
};
</script>
